import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage: React.VFC = () => (
  <Layout>
    <Seo />
    <div className="main-vision p-3 pb-md-4 mx-auto" id="main-vision">
      <div className="text-center mb-5">
        <StaticImage
          src="../images/logo_mark.png"
          width={150}
          alt="株式会社Fortune"
          placeholder="none"
        />
      </div>
      <p className="fs-6">
        全ては運命によって生かされている。株式会社Fortuneは関わる人すべてに貢献したいという想いを胸に、ビジネスを展開している企業です。
      </p>
      <p className="fs-6">
        私たちは、若者・地域社会・子ども・人・世界に向けて、常に発信をしつづけます。社名には以下のような理由を込め、この4つを達成できると幸福になれると考えています。
      </p>
      <ul className="fs-6 ps-0 lh-lg">
        <li className="list-unstyled">
          <strong className="pe-2 text-primary">Fort</strong>
          砦のように強く永く
        </li>
        <li className="list-unstyled">
          <strong className="pe-2">Com<span className="text-primary">fort</span></strong>
          笑顔で居続けることで心地よい
        </li>
        <li className="list-unstyled">
          <strong className="pe-2">Ef<span className="text-primary">fort</span></strong>
          努力をし続け
        </li>
        <li className="list-unstyled">
          <strong className="pe-2 text-primary">Fortune</strong>
          幸福で溢れた会社
        </li>
      </ul>
    </div>

    <div className="container py-3 company-data">
      <h2 className="display-7 text-center mb-4" id="company-data">会社情報</h2>

      <div className="table-responsive">
        <table className="table mb-3">
          <tbody>
            <tr>
              <th scope="row" className="text-start">会社名</th>
              <td>株式会社Fortune</td>
            </tr>
            <tr>
              <th scope="row" className="text-start">役員</th>
              <td>
                代表取締役CEO 矢嶋愛美
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">所在地</th>
              <td>
              〒606-0028<br />
              京都府京都市左京区岩倉南木野町61番地
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">電話番号</th>
              <td>
                TEL: 075-701-0123<br />
                FAX: 075-701-0133
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">事業内容</th>
              <td>
                <ul className="fs-6 ps-0 lh-base mb-0">
                  <li className="list-unstyled">実行型プロジェクトマネージメント</li>
                  <li className="list-unstyled">アパレル製品の企画、デザイン</li>
                  <li className="list-unstyled">教育・セミナー事業</li>
                  <li className="list-unstyled">飲食店の経営・企画・運営</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">設立</th>
              <td>
                2019年6月
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">資本金</th>
              <td>
                700万
              </td>
            </tr>
            <tr>
              <th scope="row" className="text-start">主要取引銀行</th>
              <td>
                京都銀行 百万遍支店<br />
                京都信用金庫 岩倉中町支店
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <h2 className="display-7 text-center mb-4 mt-4" id="business-details">事業内容</h2>
    <div className="row row-cols-1 row-cols-sm-1 g-3 business-details mx-auto pb-3 pb-md-4">
      <div className="col">
        <div className="card shadow-sm">
          <a href="https://www.instagram.com/cotoskyotohonten/" target="_blank">
            <div className="card-body">
              <h5 className="card-title fw-bold">COTO'S fruits and sweets</h5>
              <div className="row py-2 g-2">
                <div className="col-12 text-center">
                  <StaticImage
                    src="../images/daiwa_kyoto_1.jpg"
                    className="w-75"
                    alt="COTO'S fruits and sweets外観"
                  />
                </div>
                <div className="col-6">
                  <StaticImage
                    src="../images/daiwa_kyoto_2.jpg"
                    className="w-100"
                    alt="COTO'S fruits and sweets内観"
                  />
                </div>
                <div className="col-6">
                  <StaticImage
                    src="../images/daiwa_kyoto_3.jpg"
                    className="w-100"
                    alt="COTO'S fruits and sweets内観"
                  />
                </div>
              </div>
              <p className="card-text">京都高台寺にある絶品フルーツを使ったお店「COTO'S fruits and sweets」の経営・企画・運営をしております。毎日行列をつくるフルーツサンドや、こだわりのフルーツを用いたかき氷などを提供し、京都の観光地に華を添えています。</p>
            </div>
          </a>
        </div>

      </div>

    </div>

    <h2 className="display-7 text-center mt-4 contact-form" id="contact-form">お問い合わせ</h2>
    <div className="text-center mt-4">
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSf6xkrjDd4WPYHLmGBZlVsY9LSIkw8ih_84ofudDnYCcDmF-g/viewform" target="_blank" className="btn btn-lg btn-primary">お問い合わせフォーム</a>
    </div>
  </Layout>
)

export default IndexPage
